<template lang="pug">
  #sidebar.navbar-expand-lg
    #navbarSupportedContent.collapse.navbar-collapse
      .row
        .col-12
          .choose-host-navbar.mt-3
            host-select

        .col-12(v-if="permissions && permissions.length > 0")
          nav
            ul.pt-3
              li(v-if="permissions.some(p => p.Id == PermissionsEnum.Dashboard)")
                p
                  a
                    font-awesome-icon(:icon="['fas', 'tachometer-alt']")
                    router-link.ml-2(to="/home" data-toggle="collapse" data-target=".navbar-collapse.show") {{$t("partials.navigation.dashboard")}}
              li(v-if="permissions.some(p => p.Id == PermissionsEnum.Products)")
                p
                  a
                    font-awesome-icon(:icon="['fas', 'tags']")
                    router-link.ml-2(to="/products" data-toggle="collapse" data-target=".navbar-collapse.show") {{$t("partials.navigation.products")}}
              li(v-if="permissions.some(p => p.Id == PermissionsEnum.Vouchers)")
                p
                  a
                    font-awesome-icon(:icon="['fas', 'folder-open']")
                    router-link.ml-2(to="/vouchers" data-toggle="collapse" data-target=".navbar-collapse.show") {{$t("partials.navigation.vouchers")}}
              li(v-if="permissions.some(p => p.Id == PermissionsEnum.Sales)")
                p
                  a
                    font-awesome-icon(:icon="['fas', 'chart-line']")
                    router-link.ml-2(to="/sales/detail" data-toggle="collapse" data-target=".navbar-collapse.show") {{$t("partials.navigation.sales")}}

              li(v-if="permissions.some(p => p.Id == PermissionsEnum.touriBook)")
                p
                  a
                    font-awesome-icon(:icon="['fas','calendar']")
                    router-link.ml-2(:to="{ name: 'BookingTicketsList' }" data-toggle="collapse" data-target=".navbar-collapse.show") {{$t("partials.navigation.touribook")}}
                    span.badge.badge-pill.pl-1.pr-1.badge-danger.ml-1(v-if="openBookingTicketsCount") {{ openBookingTicketsCount }}

              //li(v-if="permissions.some(p => p.Id == PermissionsEnum.ExclusionPeriods)")
              li
                p
                  a
                    font-awesome-icon(:icon="['fas','calendar-xmark']")
                    router-link.ml-2(:to="{ name: 'ExclusionPeriods' }" data-toggle="collapse" data-target=".navbar-collapse.show") {{$t("partials.navigation.blockingDates")}}

              li(v-if="permissions.some(p => p.Id == PermissionsEnum.Account)")
                p
                  a
                    font-awesome-icon(:icon="['fas', 'user']")
                    a.ml-2(data-toggle="collapse" href="#my-account-collapse" aria-expanded="false" aria-controls="my-account-collapse" ) {{$t("partials.navigation.account.myaccount")}}
                      span.badge.badge-pill.pl-1.pr-1.badge-danger.ml-1(v-if="openProductContractsCount || openProductRestockContractsCount") {{ openProductContractsCount + openProductRestockContractsCount }}
                      font-awesome-icon.ml-1(:icon="['fas', 'caret-down']")

              .collapse#my-account-collapse(v-if="permissions.some(p => p.Id == PermissionsEnum.Account)")
                ul(style="padding-left:20px")
                  li(v-if="permissions.some(p => p.Id == PermissionsEnum.BaseData)")
                    p
                      router-link(to="/account/data" data-toggle="collapse" data-target=".navbar-collapse.show") {{$t("partials.navigation.account.profile")}}
                  li(v-if="permissions.some(p => p.Id == PermissionsEnum.Settlements)")
                    p
                      router-link(to="/account/settlements" data-toggle="collapse" data-target=".navbar-collapse.show") {{$t("partials.navigation.account.invoices")}}
                  li(v-if="permissions.some(p => p.Id == PermissionsEnum.Contracts)")
                    p
                      router-link(to="/account/contracts" data-toggle="collapse" data-target=".navbar-collapse.show") {{$t("partials.navigation.account.contracts")}}
                      span.badge.badge-pill.pl-1.pr-1.badge-danger.ml-1(v-if="openProductContractsCount || openProductRestockContractsCount") {{ openProductContractsCount + openProductRestockContractsCount }}
                  li(v-if="permissions.some(p => p.Id == PermissionsEnum.Documents)")
                    p
                      router-link(:to="{ name: 'Documents' }" data-toggle="collapse" data-target=".navbar-collapse.show") {{$t("partials.navigation.account.docs")}}
                  li
                    p
                      router-link(:to="{ name: 'images' }" data-toggle="collapse" data-target=".navbar-collapse.show") {{$t("partials.navigation.account.images")}}
                  li(v-if="permissions.some(p => p.Id == PermissionsEnum.DataSheet)")
                    p
                      router-link(to="/account/datasheet" data-toggle="collapse" data-target=".navbar-collapse.show") {{$t("partials.navigation.account.dataSheet")}}
                  li(v-if="permissions.some(p => p.Id == PermissionsEnum.UserManagement)")
                    p
                      router-link(to="/account/users/list" data-toggle="collapse" data-target=".navbar-collapse.show") {{$t("partials.navigation.account.userManagement")}}
                  li(v-if="permissions.some(p => p.Id == PermissionsEnum.Security)")
                    p
                      router-link(to="/account/security" data-toggle="collapse" data-target=".navbar-collapse.show") {{$t("partials.navigation.account.security")}}
                  li(v-if="permissions.some(p => p.Id == PermissionsEnum.Notifications)")
                    p
                      router-link(to="/account/notifications" data-toggle="collapse" data-target=".navbar-collapse.show") Benachrichtigungen
                  li(v-if="permissions.some(p => p.Id == PermissionsEnum.EmailSettings)")
                    p
                      router-link(to="/account/emailsettings" data-toggle="collapse" data-target=".navbar-collapse.show") E-Mail Einstellungen

              li
                p
                  a
                    font-awesome-icon(:icon="['fas','file-contract']")
                    a.ml-2(:href="$t('partials.navigation.conditionsUrl')" target="_blank") &nbsp; {{$t("partials.navigation.conditions")}}

        ContactPanel.d-none.d-xl-block

</template>

<script>
import ContactPanel from "../partials/contact-panel.vue";
import { PermissionsEnum } from "../../lib/enums/permissions/PermissionsEnum";
import EventBus from "@/views/event-bus";
import { ProductContractsComponent } from "@/lib/components/productContracts/ProductContractsComponent";
import { ProductContractStatusEnum } from "@/lib/enums/productContractStatus/ProductContractStatusEnum";
import BookingTicketsComponent from "@/lib/components/bookingTickets/BookingTicketsComponent";
import { BookingTicketStatusEnum } from "@/lib/enums/bookingTicketStatus/BookingTicketStatusEnum";

export default {
    name: "nav-sidebar",
    components: {
        ContactPanel,
    },
    mounted() {
        EventBus.$on(
            "changeHostId",
            function () {
                this.hostId = this.$session.get("hostId");
                this.loadProductContractsCount();
                this.loadBookingTicketsCount();
            }.bind(this)
        );
        EventBus.$on(
            "updateNav",
            function () {
                this.permissions = this.$session.get("permissions");
            }.bind(this)
        );
        this.loadProductContractsCount();
        this.loadBookingTicketsCount();
    },
    data() {
        return {
            hostId: this.$session.get("hostId"),
            openProductContractsCount: 0,
            openProductRestockContractsCount: 0,
            openBookingTicketsCount: 0,
            permissions: this.$session.get("permissions"),
            PermissionsEnum: PermissionsEnum,
        };
    },
    methods: {
        async loadProductContractsCount() {
            try {
                const productContractsComponent =
                    new ProductContractsComponent();
                const productContractsCount =
                    await productContractsComponent.getProductContractsCount(
                        this.$apollo,
                        this.hostId,
                        undefined,
                        undefined,
                        [ProductContractStatusEnum.Sent]
                    );
                this.openProductRestockContractsCount = productContractsCount;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadBookingTicketsCount() {
            try {
                const bookingTicketsComponent = new BookingTicketsComponent();
                const bookingTicketsCount =
                    await bookingTicketsComponent.getBookingTicketsCount(
                        this.$apollo,
                        [this.hostId],
                        [
                            BookingTicketStatusEnum.DatesRequested,
                            BookingTicketStatusEnum.DatesCancelRequested,
                        ]
                    );
                this.openBookingTicketsCount = bookingTicketsCount;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>
