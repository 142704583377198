<template lang="pug">
.fellow-travelers(v-if="fellowTravelers && Array.isArray(fellowTravelers) && fellowTravelers.length")
  .row
    .col-12
      h5 {{ $t("fellowTravelers") }}
  fellow-traveler(
    v-for="fellowTraveler in fellowTravelers"
    :fellow-traveler="fellowTraveler"
    :key="fellowTravelers.indexOf(fellowTraveler)"
  )

</template>

<script>
import FellowTraveler from "@/views/fellowTravelers/FellowTraveler.vue";

export default {
    name: "FellowTravelers",
    components: { FellowTraveler },
    props: {
        fellowTravelers: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style scoped></style>
