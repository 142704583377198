import gql from "graphql-tag";

export const Products = {
    Queries: {
        Product: gql`
        query product($id: ID, $voucherId: ID, $guid: String) {
            product(id: $id, voucherId: $voucherId, guid: $guid) {
                Id
                Guid
                HostId
                ProductVariantId
                MakairaItemId
                AfterBuyArtNr     
                Wert
                StartPreisFestPreis
                Gebuehren
                Provision
                Nights
                DealPreisLiveShopping
                Mindestverkaufspreis
                DealPreisMindestverkaufspreis
                LiveShoppingStart
                LiveShoppingEnde   
                Bestand
                Mindestbestand
                Personen
                LowStockWarningAtQuantity
                ShopHeadline
                ProdTyp
                Arrangement
                Leistungen
                Headline01
                Headline02
                Headline03
                ZusatzInfo
                Buchbarkeit
                Verpflegung
                Name1
                Name2
                Name3
                OrtRegion
                VoucherDescription
                Beschreibung02
                AdditiveInfo
                AdditionalHolidayServices
                HolidayServices
                Specials
                Eigenverkauf
                ActiveShop
                Note
                IsVisibleInShop
                IsVisibleInItemListing
                VoucherPdfTemplate
                OfferImageUrl
                EditUser
                EditDate
                IsHighlightOfTheWeekOnStartPage
                ExcludedDayOfArrivalMo
                ExcludedDayOfArrivalTu
                ExcludedDayOfArrivalWe
                ExcludedDayOfArrivalTh
                ExcludedDayOfArrivalFr
                ExcludedDayOfArrivalSa
                ExcludedDayOfArrivalSu
                ProductStatusId
                ProductTypeId
            }
        }`,
        Products: gql`
        query products($filter: String, $hostId: ID, $foreignHostId: ID, $userId: ID, $bookingTicketId: ID, $productContractId: ID, $isVisibleInItemListing: Boolean, $offset: Int, $limit: Int) {
            products(filter: $filter, hostId: $hostId, foreignHostId: $foreignHostId, userId: $userId, bookingTicketId: $bookingTicketId, productContractId: $productContractId, isVisibleInItemListing: $isVisibleInItemListing, offset: $offset, limit: $limit) {
                Id
                Guid
                HostId
                ProductVariantId
                MakairaItemId
                AfterBuyArtNr     
                Wert
                StartPreisFestPreis
                Gebuehren
                Provision
                Nights
                DealPreisLiveShopping
                Mindestverkaufspreis
                DealPreisMindestverkaufspreis
                LiveShoppingStart
                LiveShoppingEnde   
                Bestand
                Mindestbestand
                Personen
                LowStockWarningAtQuantity
                ShopHeadline
                ProdTyp
                Arrangement
                Leistungen
                Headline01
                Headline02
                Headline03
                ZusatzInfo
                Buchbarkeit
                Verpflegung
                Name1
                Name2
                Name3
                OrtRegion
                VoucherDescription
                Beschreibung02
                AdditiveInfo
                AdditionalHolidayServices
                HolidayServices
                Specials
                Eigenverkauf
                ActiveShop
                Note
                IsVisibleInShop
                IsVisibleInItemListing
                ProductTypeId
                VoucherPdfTemplate
                OfferImageUrl
                EditUser
                EditDate
                IsHighlightOfTheWeekOnStartPage
                ProductStatusId
                ProductTypeId
            }
        }`,
        ProductsCount: gql`
        query productsCount($filter: String, $hostId: ID, $foreignHostId: ID, $userId: ID, $productContractId: ID, $isVisibleInItemListing: Boolean) {
            productsCount(filter: $filter, hostId: $hostId, foreignHostId: $foreignHostId, userId: $userId, productContractId: $productContractId, isVisibleInItemListing: $isVisibleInItemListing)
        }`,
    },
};
