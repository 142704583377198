const { Products } = require("@/lib/graphql/products/Products.ts");

class ProductsComponent {
    async getProduct(apollo, id, voucherId, guid) {
        try {
            if (!apollo || (!id && !voucherId && !guid)) {
                return undefined;
            }
            const product = await apollo
                .query({
                    query: Products.Queries.Product,
                    variables: {
                        id: id,
                        voucherId: voucherId,
                        guid: guid,
                    },
                })
                .then(({ data }) => data?.product)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return product;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getProducts(apollo, bookingTicketId) {
        try {
            if (!apollo || !bookingTicketId) {
                return undefined;
            }
            const products = await apollo
                .query({
                    query: Products.Queries.Products,
                    fetchPolicy: "no-cache",
                    variables: {
                        bookingTicketId: bookingTicketId,
                    },
                })
                .then(({ data }) => data?.products)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return products;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}

module.exports = ProductsComponent;
