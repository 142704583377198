<template lang="pug">
  .card
    .card-body(style="overflow: hidden;")
      .card-title {{header}}
        .float-sm-right
          | {{$t("widgets.touriBook.book1")}}
          | &nbsp;
          .badge.badge-sm.badge-pill.badge-success {{bookingTicketsCount}}
          | &nbsp;
          | {{$tc("widgets.touriBook.book2", bookingTicketsCount)}}
      .d-table.w-100(v-if="bookingTicketsCount > 0")
        .float-left
          a.button(href="#bookingTicketCarousel" role="button" data-slide="prev")
            i(style="color:#c2c2c2")
              font-awesome-icon(:icon="['fas', 'chevron-left']")
        .float-right
          a.button(href="#bookingTicketCarousel" role="button" data-slide="next" @click="loadMoreBookingTickets")
            i(style="color:#c2c2c2")
              font-awesome-icon(:icon="['fas', 'chevron-right']")
      #bookingTicketCarousel.carousel.slide(data-ride='carousel' data-interval="false")
        .carousel-inner
          booking-ticket-panel.carousel-item(
            v-for="bookingTicket in bookingTickets"
            :booking-ticket-id="bookingTicket.Id"
            :host-ids="hostIds"
            :class="{ 'active': bookingTickets.indexOf(bookingTicket) == 0 }"
            :key="bookingTickets.indexOf(bookingTicket)"
          )

</template>

<script>
import { BookingTicketStatusEnum } from "../../../lib/enums/bookingTicketStatus/BookingTicketStatusEnum";
import BookingTicketsComponent from "@/lib/components/bookingTickets/BookingTicketsComponent";
import BookingTicketPanel from "@/views/bookingTickets/BookingTicketPanel.vue";

export default {
    name: "BookingTicketsWidget",
    components: { BookingTicketPanel },
    props: {
        header: String,
    },
    data() {
        return {
            hostIds: this.$session.get("hostIds"),
            filter: "",
            bookingTicketStatusIds: [
                BookingTicketStatusEnum.DatesRequested,
                BookingTicketStatusEnum.DatesCancelRequested,
            ],
            offset: 0,
            limit: 2,

            bookingTickets: [],
            bookingTicketsCount: 0,
        };
    },
    async mounted() {
        try {
            this.loadBookingTickets();
            this.loadBookingTicketsCount();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadMoreBookingTickets() {
            try {
                this.limit = 1;
                this.offset += 1;
                await this.loadBookingTickets();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadBookingTickets() {
            try {
                if (
                    !this.hostIds ||
                    !Array.isArray(this.hostIds) ||
                    !this.hostIds.length
                ) {
                    return;
                }
                const bookingTicketsComponent = new BookingTicketsComponent();
                const bookingTickets =
                    await bookingTicketsComponent.getBookingTickets(
                        this.$apollo,
                        this.hostIds,
                        undefined,
                        this.bookingTicketStatusIds,
                        this.filter,
                        this.offset,
                        this.limit
                    );
                if (!bookingTickets) {
                    return;
                }
                this.bookingTickets =
                    this.bookingTickets.concat(bookingTickets);
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadBookingTicketsCount() {
            try {
                if (
                    !this.hostIds ||
                    !Array.isArray(this.hostIds) ||
                    !this.hostIds.length
                ) {
                    return;
                }
                const bookingTicketsComponent = new BookingTicketsComponent();
                const bookingTicketsCount =
                    await bookingTicketsComponent.getBookingTicketsCount(
                        this.$apollo,
                        this.hostIds,
                        this.bookingTicketStatusIds,
                        this.filter
                    );
                if (bookingTicketsCount == undefined) {
                    return;
                }
                this.bookingTicketsCount = bookingTicketsCount;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
