<template lang="pug">
  .booking-date-request
    .float-left.mr-3
      template(v-if="bookingTicket.BookingTicketTypeId == BookingTicketTypesEnum.SameArrivalDay")
        span {{ bookingDateRequest.Date | formatDateWithDay($t("lang")) }}
        span(v-if="showBookingDateRequestStatus") &nbsp;({{ $t("bookingDateRequestStatus." + localBookingDateRequest.BookingDateRequestStatusId ) }})
        ul.booking-products.mt-2(v-if="combinedProducts && Array.isArray(combinedProducts) && combinedProducts.length")
          li(v-for="combinedProduct in combinedProducts")
            font-awesome-icon.mr-1(:icon="['fas', 'users']")
            span {{ combinedProduct.Personen }} {{ $t("persons") }}:&nbsp;
            font-awesome-icon.ml-2.mr-1(:icon="['fas', 'clock']")
            span {{ bookingDateRequest.Date | formatDateWithDay($t("lang")) }} - {{ moment(bookingDateRequest.Date).add(combinedProduct.Nights, 'days').toDate() | formatDateWithDay($t("lang")) }}

      template(v-else-if="bookingTicket.BookingTicketTypeId == BookingTicketTypesEnum.CombineTravelPeriod")
        span {{ bookingDateRequest.Date | formatDateWithDay($t("lang")) }} - {{ moment(bookingDateRequest.Date).add(totalNights, 'days').toDate() | formatDateWithDay($t("lang")) }}

      template(v-else-if="bookingTicket.BookingTicketTypeId == BookingTicketTypesEnum.DifferentArrivalDays")
        span(v-if="product") {{ bookingDateRequest.Date | formatDateWithDay($t("lang")) }} - {{ moment(bookingDateRequest.Date).add(product.Nights, 'days').toDate() | formatDateWithDay($t("lang")) }}

      span(v-if="bookingTicket.BookingTicketTypeId != BookingTicketTypesEnum.SameArrivalDay && showBookingDateRequestStatus") &nbsp;({{ $t("bookingDateRequestStatus." + localBookingDateRequest.BookingDateRequestStatusId ) }})

    .float-left
      template(v-if="localBookingDateRequest.BookingDateRequestStatusId == BookingDateRequestStatusEnum.DateRequested")
        input.mr-1(
          v-if="bookingTicket.IsBindingBooking"
          type="radio"
          :id="'accept-' + bookingDateRequest.Id"
          :value="BookingDateRequestStatusEnum.DateBooked"
          v-model="bookingDateRequest.BookingDateRequestStatusId"
        )
        input.mr-1(
          v-else
          type="radio"
          :id="'accept-' + bookingDateRequest.Id"
          :value="BookingDateRequestStatusEnum.DateCanBeBooked"
          v-model="bookingDateRequest.BookingDateRequestStatusId"
        )
        label(:for="'accept-' + bookingDateRequest.Id") {{ $t("accept") }}

        input.ml-2.mr-1(type="radio", :id="'reject-' + bookingDateRequest.Id" :value="BookingDateRequestStatusEnum.DateRejected" v-model="bookingDateRequest.BookingDateRequestStatusId")
        label(:for="'reject-' + bookingDateRequest.Id") {{ $t("decline") }}
      template(v-else-if="localBookingDateRequest.BookingDateRequestStatusId == BookingDateRequestStatusEnum.DateCancelRequested")
        input.mr-1(type="radio", :id="'accept-' + bookingDateRequest.Id" :value="BookingDateRequestStatusEnum.DateCancelled" v-model="bookingDateRequest.BookingDateRequestStatusId")
        label(:for="'accept-' + bookingDateRequest.Id") {{ $t("accept") }}

        input.ml-2.mr-1(type="radio", :id="'reject-' + bookingDateRequest.Id" :value="BookingDateRequestStatusEnum.DateBooked" v-model="bookingDateRequest.BookingDateRequestStatusId")
        label(:for="'reject-' + bookingDateRequest.Id") {{ $t("decline") }}

</template>

<script>
import { VouchersComponent } from "@/lib/components/vouchers/VouchersComponent";
import ProductsComponent from "@/lib/components/products/ProductsComponent";
import moment from "moment";
import { BookingTicketTypesEnum } from "@/lib/enums/bookingTicketTypes/BookingTicketTypesEnum.ts";
import { BookingDateRequestStatusEnum } from "@/lib/enums/bookingDateRequestStatus/BookingDateRequestStatusEnum";

export default {
    name: "BookingDateRequest",
    props: {
        bookingDateRequest: {
            type: Object,
            required: true,
        },
        bookingTicket: {
            type: Object,
            required: true,
        },
        bookingTicketVoucher: {
            type: Object,
            required: false,
        },
        bookingTicketVouchers: {
            type: Array,
            required: false,
        },
        showBookingDateRequestStatus: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            localBookingDateRequest: JSON.parse(
                JSON.stringify(this.bookingDateRequest)
            ),
            moment: moment,
            products: [],
            combinedProducts: [],
            nights: [],
            totalNights: 0,
            voucher: undefined,
            BookingTicketTypesEnum: BookingTicketTypesEnum,
            BookingDateRequestStatusEnum: BookingDateRequestStatusEnum,
        };
    },
    async mounted() {
        try {
            this.handleBookingTicketVouchers();
            this.loadVoucher();
            this.loadProducts();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async handleBookingTicketVouchers() {
            try {
                if (
                    !this.bookingTicketVouchers ||
                    !Array.isArray(this.bookingTicketVouchers) ||
                    !this.bookingTicketVouchers.length
                ) {
                    return;
                }
                const products = [];
                const nights = [];
                let totalNights = 0;
                const productsComponent = new ProductsComponent();
                for (const bookingTicketVoucher of this.bookingTicketVouchers) {
                    const product = await productsComponent.getProduct(
                        this.$apollo,
                        undefined,
                        bookingTicketVoucher.VoucherId
                    );
                    if (!product) {
                        continue;
                    }
                    products.push(product);
                    nights.push(product.Nights);
                    totalNights += product.Nights;
                }
                this.products = products;
                this.nights = nights;
                this.totalNights = totalNights;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadVoucher() {
            try {
                if (!this.bookingTicketVoucher) {
                    return;
                }
                const vouchersComponent = new VouchersComponent();
                const voucher = await vouchersComponent.getVoucher(
                    this.$apollo,
                    this.bookingTicketVoucher?.VoucherId
                );
                if (!voucher) {
                    return;
                }
                this.voucher = voucher;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadProducts() {
            try {
                if (!this.voucher) {
                    return;
                }
                const productsComponent = new ProductsComponent();
                const products = await productsComponent.getProducts(
                    this.$apollo,
                    this.voucher?.ProductId
                );
                if (!products) {
                    return;
                }
                this.products = products;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        handleCombinedProducts() {
            try {
                if (
                    !this.products ||
                    !Array.isArray(this.products) ||
                    !this.products.length
                ) {
                    return undefined;
                }
                const combinedProducts = [];
                for (const product of this.products) {
                    let combinedProduct = combinedProducts.find(
                        (combinedProduct) => combinedProduct.Id == product.Id
                    );
                    if (!combinedProduct) {
                        combinedProducts.push(product);
                    } else {
                        combinedProduct.Personen += product.Personen;
                    }
                }
                this.combinedProducts = combinedProducts;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
    },
    watch: {
        "bookingDateRequest.Id"() {
            try {
                this.localBookingDateRequest = JSON.parse(
                    JSON.stringify(this.bookingDateRequest)
                );
                this.handleBookingTicketVouchers();
                this.loadVoucher();
                this.loadProducts();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        "bookingDateRequest.Date"() {
            try {
                this.localBookingDateRequest = JSON.parse(
                    JSON.stringify(this.bookingDateRequest)
                );
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        bookingTicketVouchers() {
            try {
                this.handleBookingTicketVouchers();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        bookingTicketVoucher() {
            try {
                if (!this.bookingTicketVoucher) {
                    return;
                }
                this.loadVoucher();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        products() {
            try {
                this.handleCombinedProducts();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
